export class FormValidator {
  public static required(label: string): (value: string) => boolean | string {
    return (value: string) => !!value || `${label}を入力してください。`;
  }

  public static password(label: string): (value: string) => boolean | string {
    const pattern = /^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{10,}$/;
    return (value: string) =>
      !value || pattern.test(value) || `${label}は10文字以上で、数字、アルファベット大文字、アルファベット小文字、記号を全て1文字以上含めてください。`;
  }
}
