import { render, staticRenderFns } from "./License.vue?vue&type=template&id=9d0e91a4&scoped=true&"
import script from "./License.vue?vue&type=script&lang=ts&"
export * from "./License.vue?vue&type=script&lang=ts&"
import style0 from "./License.vue?vue&type=style&index=0&id=9d0e91a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d0e91a4",
  null
  
)

export default component.exports