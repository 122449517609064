

























































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: "AccountConfirm",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    context.emit("show-bar", false);
    const token_value = ref(props.token);
    const password = ref("");
    const password2 = ref("");
    const information_visible = ref(false);
    const information_message = ref("");
    const form = ref();
    const success_dialog = ref(false);
    const verified_token = ref(false);

    const load = async () => {
      try {
        await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/approve/${props.token}`);
        verified_token.value = true;
      } catch (e) {
        await router.push({ name: "Login" });
      }
    };
    const validatePassword = (text: string) => {
      if (!text) {
        return "パスワードを入力してください。";
      }
      if (!/^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{10,}$/.test(text)) {
        return "パスワードは10文字以上で、数字、アルファベット大文字、アルファベット小文字、記号を全て1文字以上含めてください。";
      }
      return true;
    };

    const validatePassword2 = (text: string) => {
      if (!text) {
        return "確認のため、上と同じパスワードをもう一度入力してください。";
      }
      if (password.value && text !== password.value) {
        return "パスワードとパスワード(確認)が一致しません。";
      }
      return true;
    };

    const confirm = async () => {
      if (!form.value.validate()) {
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/approve/`, {
          token: token_value.value,
          password: password.value,
        });
        success_dialog.value = true;
      } catch (e) {
        information_message.value = "パスワードの変更に失敗しました。";
        information_visible.value = true;
      }
    };

    const toLogin = () => {
      success_dialog.value = false;
      router.push({ name: "Login" });
    };

    onMounted(async () => {
      await load();
    });

    return {
      form,
      token_value,
      password,
      password2,
      information_message,
      information_visible,
      success_dialog,
      verified_token,
      toLogin,
      validatePassword,
      validatePassword2,
      confirm,
    };
  },
});
