






import { defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { StaticService } from "@/services/static.service";

export default defineComponent({
  name: "StaticContents",
  props: {
    content_id: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    context.emit("show-bar", true);

    const { content_id } = toRefs(props);
    const content_url = ref<string>();

    const service = new StaticService();
    const getStaticContent = async (content_id: string) => {
      await service
        .getContentUrl(content_id)
        .then((url) => (content_url.value = url))
        .catch((e) => console.error(e));
    };

    onMounted(async () => {
      await getStaticContent(props.content_id);
    });

    watch(content_id, async () => {
      await getStaticContent(props.content_id);
    });

    return {
      content_url,
    };
  },
});
