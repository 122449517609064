






































































































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import axios from "axios";
import L from "leaflet";
import { LatLng, LeafletMouseEvent } from "leaflet";
import { LMap, LMarker, LTileLayer, LTooltip, LControl } from "vue2-leaflet";

interface GeoFenceType {
  month: string;
  lat: number;
  lon: number;
  supplement: number;
  supplementary_text: string;
}

interface LocalState {
  this_month: GeoFenceType;
  next_month: GeoFenceType;
}

interface MapOptions {
  center: LatLng;
  zoom: number;
  selected_point: LatLng;
}

export default defineComponent({
  name: "GeoFence",
  components: { LMap, LTileLayer, LMarker, LTooltip, LControl },
  setup(props, context) {
    context.emit("show-bar", true);
    const title_row = ref();
    const title_row_height = ref("auto");
    const loading = ref(true);
    const default_position = new LatLng(35.6809002, 139.7657871);
    const this_month_map = reactive<MapOptions>({
      center: new LatLng(0, 0),
      zoom: 15,
      selected_point: new LatLng(0, 0),
    });
    const next_month_map = reactive<MapOptions>({
      center: new LatLng(0, 0),
      zoom: 15,
      selected_point: new LatLng(0, 0),
    });
    const url = ref("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    const state = reactive<LocalState>({
      this_month: { month: "", lat: 0, lon: 0, supplement: 0, supplementary_text: "" },
      next_month: { month: "", lat: 0, lon: 0, supplement: 0, supplementary_text: "" },
    });
    const show_information = ref(false);
    const information_message = ref("");
    const show_this_map = ref(false);
    const show_next_map = ref(false);
    const show_update_marker = ref(false);
    const update_marker_position = ref();
    const update_marker_icon = ref(
      L.icon({
        iconUrl: require("../assets/marker-icon-green.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
      })
    );
    const load = async () => {
      try {
        const response = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/geofence/`)).data as LocalState;
        state.this_month = response.this_month;
        state.next_month = response.next_month;
        if (state.this_month.lat && state.this_month.lon) {
          show_this_map.value = true;
          this_month_map.center = new LatLng(state.this_month.lat, state.this_month.lon);
          this_month_map.selected_point = new LatLng(state.this_month.lat, state.this_month.lon);
        }
        if (state.next_month.lat && state.next_month.lat) {
          next_month_map.center = new LatLng(state.next_month.lat, state.next_month.lon);
          next_month_map.selected_point = new LatLng(state.next_month.lat, state.next_month.lon);
        } else {
          next_month_map.center = default_position;
          next_month_map.selected_point = default_position;
        }
        show_next_map.value = true;
        show_update_marker.value = false;
      } catch (e) {
        information_message.value = "マイロケーション情報を読み込みできません。";
        show_information.value = true;
        console.error(e);
      }
      loading.value = false;
    };
    const update = async () => {
      try {
        if (show_update_marker.value) {
          state.next_month.lat = update_marker_position.value.lat;
          state.next_month.lon = update_marker_position.value.lng;
        } else {
          state.next_month.lat = next_month_map.selected_point.lat;
          state.next_month.lon = next_month_map.selected_point.lng;
        }
        //経度の補正
        //東西にスクロールすると−180〜180を超えるので調整する
        while (state.next_month.lon < -180.0 || 180.0 < state.next_month.lon) {
          if (state.next_month.lon < -180.0) {
            state.next_month.lon += 360.0;
          } else {
            state.next_month.lon -= 360.0;
          }
        }
        if (state.next_month.supplement !== 3) {
          state.next_month.supplementary_text = "";
        }
        await axios.post(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/geofence/`, state.next_month);
        await load();
        information_message.value = "来月のマイロケーション情報を更新しました。";
        show_information.value = true;
        updatable.value = false;
      } catch (e) {
        information_message.value = "来月のマイロケーション情報の更新に失敗しました。";
        show_information.value = true;
      }
    };
    onMounted(async () => {
      await load();
      onResize();
    });

    const onClickNextMap = (e: LeafletMouseEvent) => {
      // next_month_map.selected_point = e.latlng;
      show_update_marker.value = true;
      update_marker_position.value = e.latlng;
      isUpdatable();
    };
    const updatable = ref(false);
    const isUpdatable = () => {
      if (!state.next_month.supplement) {
        updatable.value = false;
      } else {
        updatable.value = !(state.next_month.supplement === 3 && !state.next_month.supplementary_text);
      }
    };
    const toCenter = (n: number) => {
      if (n === 1) {
        if (state.this_month.lat && state.this_month.lon) {
          this_month_map.center = new LatLng(state.this_month.lat, state.this_month.lon);
        }
      } else if (n === 2) {
        if (state.next_month.lat && state.next_month.lat) {
          next_month_map.center = new LatLng(state.next_month.lat, state.next_month.lon);
        } else {
          next_month_map.center = default_position;
        }
      }
    };
    const updateCenter = (n: number, p: LatLng) => {
      if (n === 1) {
        this_month_map.center = p;
      } else if (n === 2) {
        next_month_map.center = p;
      }
    };
    const onResize = () => {
      if (window.innerWidth > 959) {
        if (title_row.value?.offsetHeight) {
          title_row_height.value = title_row.value.offsetHeight + "px";
        }
      } else {
        title_row_height.value = "auto";
      }
    };

    const isShowRadio = (n: number) => {
      if (window.innerWidth > 959) {
        return true;
      } else {
        console.log("isShowRadio");
        return n === state.this_month.supplement;
      }
    };

    return {
      state,
      update,
      url,
      this_month_map,
      next_month_map,
      onClickNextMap,
      show_information,
      information_message,
      show_this_map,
      show_next_map,
      loading,
      updatable,
      isUpdatable,
      show_update_marker,
      update_marker_position,
      toCenter,
      updateCenter,
      update_marker_icon,
      onResize,
      title_row,
      title_row_height,
      isShowRadio,
    };
  },
});
