<template>
  <v-app-bar app color="primary" dark class="srm-toolbar" :style="styleBar">
    <v-app-bar-nav-icon v-if="useHamburger" @click="toggleNavigation"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title class="text-h4">
      <v-img max-height="46" style="margin-top: 4px;" contain :src="require('../../assets/header-log.png')"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-img max-width="38" style="margin: 10px;" contain :src="require('../../assets/light-bulb.png')"></v-img>
  </v-app-bar>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppBar",
  props: {
    styleBar: { type: String, default: "" },
    useHamburger: { type: Boolean, default: false },
  },
  setup(prop, context) {
    const toggleNavigation = (e) => context.emit("toggle", e);
    return { toggleNavigation };
  },
});
</script>

<style scoped></style>
