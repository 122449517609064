import axios, { AxiosInstance } from "axios";

export class BaseService {
    readonly client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_CONSUMER_API_ENDPOINT,
            withCredentials: true,
        });
    }
}