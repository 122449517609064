




import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "License",
  props: {
    type: { type: String, default: "dummy" },
  },
  setup(props, context) {
    const iframe = ref();
    const licenseSrc = `/static/license/${props.type}.html`;

    onMounted(() => {
      iframe.value.contentWindow.addEventListener("scroll", () => {
        const scrollAdjustmentValue = 50;
        const scrollHeight = iframe.value.contentDocument?.documentElement.scrollHeight || 0;
        // スクロール量
        const scrollAmount = iframe.value.contentWindow.scrollY || 0;
        // ウィンドウ（表示される枠）の高さ
        const windowHeight = iframe.value.contentWindow.innerHeight || 0;

        if (scrollAmount + windowHeight + scrollAdjustmentValue > scrollHeight) {
          context.emit("scroll:complete");
        }
      });
    });

    return { licenseSrc, iframe };
  },
});
