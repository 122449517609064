import { Middleware } from "@/router/middlewares/middleware";
import { NavigationGuardNext, Route } from "vue-router";
import Vue from "vue";
import axios from "axios";
import { store } from "@/store";

export class AuthGuardMiddleware implements Middleware {
  async beforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>): Promise<boolean> {
    console.debug("process - auth guard middleware");

    if (!to.meta?.needLogin) {
      next();
      return Promise.resolve(false);
    }

    try {
      const login = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/login/`, { withCredentials: true })).data;
      store.commit("login", login);
      const ua = to.name === "UserAgreement";

      if (login.agreed_at && !ua) {
        if (to.name === "Home" && !login.is_owner && !login.is_manager) {
          next({ name: "Contents" });
        } else {
          next();
        }
      } else if (login.agreed_at && ua) {
        next({ name: "Home" });
      } else {
        if (ua) {
          next();
        } else {
          next({ name: "UserAgreement", query: to.query });
        }
      }
    } catch (e) {
      store.commit("logout");
      next({ name: "Login" });
    }
    return Promise.resolve(true);
  }
}
