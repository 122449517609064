import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#439b47",
        secondary: "#004499",
        tertiary: "#757575",
        footer: "#A0A0A0",
      },
    },
  },
});
