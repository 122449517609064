




























































import { defineComponent, ref } from "@vue/composition-api";
import axios from "axios";
import { FormValidator } from "@/utils/form-validator";
import router from "@/router";

export default defineComponent({
  name: "PasswordChange",
  setup(props, context) {
    context.emit("show-bar", true);
    const old_pass = ref("");
    const new_pass = ref("");
    const new_pass2 = ref("");
    const information_visible = ref(false);
    const information_message = ref("");
    const success_dialog = ref(false);

    const form = ref();
    const old_password = ref();
    const new_password = ref();
    const old_password_error = ref(false);
    const old_password_error_messages = ref([] as string[]);
    const new_password_error = ref(false);
    const new_password_error_messages = ref([] as string[]);

    const validators = {
      old_password: [FormValidator.required("現在のパスワード")],
      new_password: [
        FormValidator.required("新しいパスワード"),
        FormValidator.password("新しいパスワード"),
        () => old_pass.value !== new_pass.value || "新しいパスワードは現在のパスワードと違うものを設定してください。",
      ],
      new_password2: [
        FormValidator.required("新しいパスワード(確認)"),
        () => new_pass.value === new_pass2.value || "新しいパスワードと新しいパスワード(確認)が一致しません。",
      ],
    };
    const save = async () => {
      if (!form.value.validate()) {
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/password/change/`, {
          old_password: old_pass.value,
          new_password: new_pass.value,
        });
        success_dialog.value = true;
      } catch (e) {
        if (e.response.status === 400) {
          if (e.response.data.old_password) {
            old_password_error.value = true;
            old_password_error_messages.value = e.response.data.old_password;
          }
          if (e.response.data.new_password) {
            new_password_error.value = true;
            new_password_error_messages.value = e.response.data.new_password;
          }
        } else {
          information_message.value = "パスワードの変更に失敗しました。";
          information_visible.value = true;
        }
      }
    };
    const toLogin = () => {
      success_dialog.value = false;
      router.push({ name: "Login" });
    };

    return {
      save,
      information_visible,
      information_message,
      old_pass,
      new_pass,
      new_pass2,
      validators,
      form,
      old_password,
      new_password,
      success_dialog,
      toLogin,
      old_password_error,
      old_password_error_messages,
      new_password_error,
      new_password_error_messages,
    };
  },
});
