import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import Contents from "@/views/Contents.vue";
import AccountConfirm from "@/views/AccountConfirm.vue";
import ContentView from "@/views/ContentView.vue";
import UserAgreement from "@/views/UserAgreement.vue";
import StaticContents from "@/views/StaticContents.vue";
import PasswordReminder from "@/views/PasswordReminder.vue";
import GeoFence from "@/views/GeoFence.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import PasswordChange from "@/views/PasswordChange.vue";
import { Middleware } from "@/router/middlewares/middleware";
import { ProxyTokenMiddleware } from "@/router/middlewares/proxy-token.middleware";
import { AuthTokenMiddleware } from "@/router/middlewares/auth-token.middleware";
import { AuthGuardMiddleware } from "@/router/middlewares/auth-guard.middleware";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      needLogin: true,
      title: "トップ",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      needLogin: false,
      title: "ログイン",
    },
  },
  {
    path: "/password/reminder",
    name: "PasswordReminder",
    component: PasswordReminder,
    meta: {
      needLogin: false,
      title: "パスワード再設定",
    },
  },
  {
    path: "/password/reset/:token",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      needLogin: false,
      title: "パスワードリセット",
    },
    props: (route) => ({
      token: String(route.params.token),
    }),
  },
  {
    path: "/agreement",
    name: "UserAgreement",
    component: UserAgreement,
    meta: {
      needLogin: true,
      title: "利用規約",
    },
  },
  {
    path: "/contents",
    name: "Contents",
    component: Contents,
    meta: {
      needLogin: true,
      title: "アドバイス一覧",
    },
  },
  {
    path: "/contents/:driver_id",
    name: "ContentsByDriver",
    component: Contents,
    meta: {
      needLogin: true,
      title: "アドバイス一覧",
    },
    props: (route) => ({
      driver_id: String(route.params.driver_id),
    }),
  },
  {
    path: "/contents/view/:content_id",
    name: "ContentView",
    component: ContentView,
    meta: {
      needLogin: true,
      title: "コンテンツ",
    },
    props: (route) => ({
      content_id: String(route.params.content_id),
    }),
  },
  {
    path: "/pages/:content_id",
    name: "StaticContents",
    component: StaticContents,
    meta: {
      needLogin: true,
      title: "ページ",
    },
    props: (route) => ({
      content_id: String(route.params.content_id),
    }),
  },
  {
    path: "/confirm/:token",
    name: "AccountConfirm",
    component: AccountConfirm,
    meta: {
      needLogin: false,
      title: "アカウント登録確認",
    },
    props: (route) => ({
      token: String(route.params.token),
    }),
  },
  {
    path: "/geofence",
    name: "GeoFence",
    component: GeoFence,
    meta: {
      needLogin: true,
      title: "ジオフェンス設定",
    },
  },
  {
    path: "/password/change",
    name: "PasswordChange",
    component: PasswordChange,
    meta: {
      needLogin: true,
      title: "パスワード変更",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await runMiddleware(to, from, next, [new ProxyTokenMiddleware(), new AuthTokenMiddleware(), new AuthGuardMiddleware()]);
});

router.afterEach((to, from) => {
  document.title = "Telema Coaching" + (to.meta?.title ? " - " + to.meta?.title : "");
});

const runMiddleware = async (to: Route, from: Route, next: NavigationGuardNext<Vue>, classs: Middleware[]) => {
  for (const cla of classs) {
    const is_next = await cla.beforeEach(to, from, next);
    if (!is_next) {
      return;
    }
  }
};

export default router;
