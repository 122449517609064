import Vue from "vue";
import Vuex from "vuex";
import { User } from "@/models/model";

Vue.use(Vuex);

interface State {
  login:boolean;
  login_user: User;
}

export const store = new Vuex.Store<State>({
  state: {
    login:false,
    login_user: {} as User,
  },
  mutations: {
    login(state, user) {
      state.login=true;
      state.login_user = user;
    },
    logout(state){
      state.login=false;
      state.login_user = {} as User;
    }
  },
  actions: {},
  modules: {},
});
