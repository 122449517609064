






import { defineComponent, onMounted, ref } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";

export default defineComponent({
  name: "ContentView",
  props: {
    content_id: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    context.emit("show-bar", true);
    let url = `${process.env.VUE_APP_CONTENTS_VIEW_ENDPOINT}/${props.content_id}`;
    if (router.app.$route.query.token) {
      url += `/?token=${router.app.$route.query.token}`;
    }
    const content_url = ref(url);

    const load = async () => {
      try {
        const contents = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/contents/${props.content_id}`)).data;
        document.title = `Telema Coaching - ${contents.title}`;
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      await load();
    });
    return {
      content_url,
    };
  },
});
