






























import { defineComponent, ref } from "@vue/composition-api";
import { store } from "@/store";
import axios from "axios";
import router from "@/router";
import License from "@/components/license/License.vue";
import AppBar from "@/components/navigation/AppBar.vue";

export default defineComponent({
  name: "UserAgreement",
  components: {
    AppBar,
    License,
  },
  setup(props, context) {
    const is_manager = ref(store.state.login_user.is_manager || store.state.login_user.is_owner);
    const checker = ref(false);
    const checker_disabled = ref(true);

    const information_visible = ref(false);
    const information_message = ref("");

    const sendAgreement = async () => {
      const url = `${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/agreement/`;
      const data = {};
      const option = {
        withCredentials: true,
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
      };

      await axios
        .post(url, data, option)
        .then(() => router.push({ name: "Home" }))
        .catch(() => {
          information_message.value = "利用規約の同意に失敗しました。再度お試しください。";
          information_visible.value = true;
        });
    };

    const scrollComplete = () => {
      checker_disabled.value = false;
    };

    return {
      checker,
      is_manager,
      information_visible,
      information_message,
      checker_disabled,
      sendAgreement,
      scrollComplete,
    };
  },
});
