














































import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";
import { store } from "@/store";
import axios from "axios";
import router from "@/router";
import AppBar from "@/components/navigation/AppBar.vue";

interface StaticPage {
  id: string;
  title: string;
  iframe: boolean;
  content_path: string;
}

export default defineComponent({
  name: "App",
  components: { AppBar },
  setup(props, ctx) {
    const show_app_bar = ref(store.state.login);
    const show_navigation = ref(!ctx.root.$vuetify.breakpoint.mobile);
    const navigation_width = ref(256);
    const right_margin = ref(show_app_bar.value ? 0 : navigation_width.value);
    const feature_flag_ph2 = process.env.FEATURE_FLAG_PH2 ?? false;
    const statics = reactive<any[]>([]);
    const is_statics = ref(false);
    const static_id = ref("");

    const onShowBar = async (b: boolean) => {
      await getStaticContents();
      show_app_bar.value = b;
    };
    const toggleNavigation = () => {
      show_navigation.value = !show_navigation.value;
    };
    const getRightMargin = () => {
      return !show_app_bar.value ? 0 : show_navigation.value ? navigation_width.value : 0;
    };
    const logout = async () => {
      try {
        await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/logout/`, { withCredentials: true });
        delete axios.defaults.headers.common["Authorization"];
        store.commit("logout");
        await router.push({ name: "Login" });
      } catch (e) {
        console.error(e);
      }
    };

    const openStaticPage = (item: StaticPage) => {
      if (item.iframe) {
        router.push(`/pages/${item.id}`).catch(() => ({}));
      } else {
        const w = window.open(item.content_path, "_blank");
        if (w != null) {
          w.opener = null;
        }
      }
    };

    const getStaticContents = async () => {
      await axios
        .get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/static/`, { withCredentials: true })
        .then((e) => {
          statics.splice(0);
          statics.push(...e.data);
        })
        .catch((e) => console.log(e));
    };

    onMounted(async () => {
      await getStaticContents();
    });

    watch(
      () => ctx.root.$route,
      async (to) => {
        if (to.name === "StaticContents") {
          is_statics.value = true;
          static_id.value = to.params.content_id;
        } else {
          is_statics.value = false;
          static_id.value = "";
        }
      }
    );
    return {
      show_app_bar,
      show_navigation,
      navigation_width,
      right_margin,
      feature_flag_ph2,
      statics,
      onShowBar,
      toggleNavigation,
      getRightMargin,
      logout,
      openStaticPage,
      is_statics,
      static_id,
    };
  },
});
