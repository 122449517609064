import { BaseService } from "@/services/base.service";

export class StaticService extends BaseService {
  getContentUrl(content_id: string): Promise<any> {
    return this.client.get(`/static/${content_id}`).then((e) => {
      const path = e.data.content_path;
      const regexp = /^(http|https):\/\//;
      return regexp.test(path) ? path : `${process.env.VUE_APP_CONTENTS_STATIC_ENDPOINT}/${path}`;
    });
  }
}
