import { NavigationGuardNext, Route } from "vue-router";
import Vue from "vue";
import axios from "axios";
import { Middleware } from "@/router/middlewares/middleware";

export class ProxyTokenMiddleware implements Middleware {
  async beforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>): Promise<boolean> {
    console.debug("process - proxy token middleware");

    if (!to.query.proxy_token) {
      return true;
    }

    const url = `${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/proxy/`;
    const proxy = await axios.post(url, { proxy_token: to.query.proxy_token })
      .then((res) => res.data)
      .catch((e) => {
        console.error(e);
        return null;
      });

    if (proxy) {
      next({ name: to.name as string, params: to.params, query: { token: proxy.token } });
      return false;
    }

    return true;
  }
}
