import { Middleware } from "@/router/middlewares/middleware";
import { NavigationGuardNext, Route } from "vue-router";
import axios from "axios";
import Vue from "vue";

export class AuthTokenMiddleware implements Middleware {
  async beforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>): Promise<boolean> {
    console.debug("process - auth token middleware");

    if (to.query.token) {
      axios.defaults.headers.common["Authorization"] = `Token ${to.query.token}`;
    }

    if (from.query.token && !to.query.token && to.name !== "Login") {
      next({ name: to.name as string, params: to.params, query: { token: from.query.token } });
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}