














































































import { computed, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { Advice, User } from "@/models/model";
import axios from "axios";
import router from "@/router";

interface LocalState {
  user: User;
}

export default defineComponent({
  name: "Contents",
  props: {
    driver_id: {
      type: String,
    },
  },
  setup(props, context) {
    context.emit("show-bar", true);
    const info = ref();
    const calendar = ref();
    const card_height = ref(500);
    const state = reactive<LocalState>({
      user: {
        id: "",
        office: "",
        name: "",
        is_driver: false,
        is_manager: false,
        is_owner: false,
        started_at: "",
        organization: { started_at: "", name: "", drivers_count: 0 },
      },
    });
    const advices = ref<Advice[]>([]);

    const calendar_type = ref("month");
    const types = ref([
      { text: "MONTH", value: "month" },
      { text: "WEEK", value: "week" },
      { text: "DAY", value: "day" },
    ]);
    const events = ref([]);
    const calendar_value = ref("");

    const information_visible = ref(false);
    const information_message = ref("");
    const feature_flag_ph3 = process.env.FEATURE_FLAG_PH3 ?? false;
    const loading = ref(true);

    const load = async () => {
      loading.value = true;
      try {
        const login_user = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/login/`, { withCredentials: true })).data;
        const user_id = props.driver_id ? props.driver_id : login_user.id;
        state.user = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/driver/${user_id}/`, { withCredentials: true })).data;
        events.value = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/calendar/${user_id}/`, { withCredentials: true })).data;
        advices.value = (await axios.get(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/advice/${user_id}/`, { withCredentials: true })).data;
        document.title = `Telema Coaching - ${state.user.name} 様のコンテンツ一覧`;
      } catch (e) {
        information_message.value = "表示情報の取得に失敗しました。";
        information_visible.value = true;
      }
      loading.value = false;
    };

    onMounted(async () => {
      await load();
      onResize();
    });

    const calendar_title = computed(() => {
      const date = calendar.value.parsedValue;
      return `${date.year}年${date.month}月`;
    });

    const onResize = () => {
      if (info.value) {
        if (context.root.$vuetify.breakpoint.mdAndUp) {
          card_height.value = window.innerHeight - info.value.$el.clientHeight - 184;
        } else {
          card_height.value = 200;
        }
      }
    };
    const todayCalendar = () => {
      calendar_value.value = "";
    };
    const prevCalendar = () => {
      calendar.value.prev();
    };

    const nextCalendar = () => {
      calendar.value.next();
    };

    const onClickEvent = (event: any) => {
      if (event.event.id) {
        router.push({ name: "ContentView", params: { content_id: event.event.id } });
      }
    };

    return {
      calendar_type,
      types,
      advices,
      events,
      info,
      card_height,
      state,
      information_visible,
      information_message,
      calendar,
      calendar_value,
      feature_flag_ph3,
      loading,
      calendar_title,
      onResize,
      prevCalendar,
      nextCalendar,
      todayCalendar,
      onClickEvent,
    };
  },
});
