









































import { defineComponent, ref } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: "PasswordReminder",
  setup() {
    const login_id = ref("");
    const success_dialog = ref(false);
    const information_visible = ref(false);
    const information_message = ref("");

    const sendmail = async () => {
      try {
        await axios.post(`${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/password/reminder/`, { login_id: login_id.value });
        success_dialog.value = true;
      } catch (e) {
        information_message.value = "パスワード再設定メールの送信に失敗しました。";
        information_visible.value = true;
      }
    };

    return {
      login_id,
      information_message,
      information_visible,

      success_dialog,
      sendmail,
    };
  },
});
