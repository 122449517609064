














































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import router from "@/router";
import { FormValidator } from "@/utils/form-validator";
import axios from "axios";
import { load } from "recaptcha-v3";

interface LocalState {
  login_id: string;
  password: string;
  token: string;
}

export default defineComponent({
  name: "Login",
  setup(props, context) {
    context.emit("show-bar", false);
    const model = reactive<LocalState>({
      login_id: "",
      password: "",
      token: "",
    });

    const loginIdRules = [FormValidator.required("ログインID")];
    const passwordRules = [FormValidator.required("パスワード")];

    const form = ref();
    const password_input = ref();
    const id_input = ref();

    const errorMessage = ref<string>();

    const login = async () => {
      const isValid = form.value.validate();
      if (!isValid) return;

      // get reCAPTCHA v3 token
      const site_key = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      const recapthca = await load(site_key)
      const token = await recapthca.execute('login');
      model.token = token;

      errorMessage.value = "";

      const url = `${process.env.VUE_APP_CONSUMER_API_ENDPOINT}/login/`;
      const config = { withCredentials: true };

      const isSuccess = await axios
        .post(url, model, config)
        .then(() => true)
        .catch((e) => {
          console.error(e);
          if (e.response.status === 401) {
            errorMessage.value = "ログインIDまたはパスワードが間違っています。";
          } else {
            errorMessage.value = "このサイトをご利用いただけません。";
          }
          toId();
          return false;
        });

      if (isSuccess) {
        location.href = "/";
      }
    };
    const toId = () => {
      if (id_input.value) {
        id_input.value.focus();
      }
    };
    const toPassword = () => {
      if (password_input.value) {
        password_input.value.focus();
      }
    };
    onMounted(() => {
      toId();
    });
    return { form, model, login, loginIdRules, passwordRules, errorMessage, password_input, id_input, toPassword };
  },
});
